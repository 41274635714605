import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Avatar, Box, Divider, Drawer, Hidden, Link, Typography } from '@material-ui/core';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import useAuth from '../../hooks/useAuth';
import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import FolderOpenIcon from '../../icons/FolderOpen';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import CreditCardIcon from '../../icons/CreditCard';
import UsersIcon from '../../icons/Users';
import TemplateIcon from '../../icons/Template';
import Logotfs from '../Logotfs';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

const sections = [
  {
    title: 'Generale',
    items: [
      {
        title: 'Panoramica',
        path: '/',
        icon: <ChartSquareBarIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Gestione',
    items: [
      {
        title: 'Clienti',
        icon: <UsersIcon fontSize="small" />,
        children: [
          {
            title: 'Tutti i clienti',
            path: '/customers'
          },
          {
            title: 'Codici sconto',
            path: '/coupons'
          }
        ]
      },
      {
        title: 'Prodotti',
        icon: <ShoppingCartIcon fontSize="small" />,
        children: [
          {
            title: 'Tutti i prodotti',
            path: '/products'
          },
          {
            title: 'Inventario',
            path: '/products/variations'
          },
          {
            title: 'Marche',
            path: '/products/brands'
          },
          {
            title: 'Tipologie',
            children: [
              {
                title: 'Tutte le tipologie',
                path: '/products/types'
              },
              {
                title: 'Categorie',
                path: '/products/categories'
              }
            ]
          },
          {
            title: 'Colori',
            path: '/products/colors'
          },
          {
            title: 'Taglie',
            children: [
              {
                title: 'Tutte le taglie',
                path: '/products/sizes'
              },
              {
                title: 'Scale taglie',
                path: '/products/size_categories'
              },
              {
                title: 'Misure',
                path: '/products/size_charts'
              }

            ]
          },
          {
            title: 'Stagioni',
            path: '/products/seasons'
          },
          {
            title: 'Etichette',
            path: '/products/labels'
          }
        ]
      },
      {
        title: 'Ordini',
        icon: <FolderOpenIcon fontSize="small" />,
        children: [
          {
            title: 'Tutti gli ordini',
            path: '/orders'
          },
          {
            title: 'Vendite articoli',
            path: '/orders/items'
          }
        ]
      },
      {
        title: 'Magazzini',
        icon: <AllInboxIcon fontSize="small" />,
        children: [
          // {
          //   title: 'Tutti i magazzini',
          //   path: '/warehouses'
          // },
          {
            title: 'Movimenti',
            path: '/movements'
          },
          {
            title: 'DDT',
            path: '/ddt'
          },
          {
            title: 'Riassortimenti',
            path: '/restocking'
          },
          {
            title: 'eBay (esterno)',
            path: 'https://feed.thefirmshop.com/?token=rLRbKoLUdMpXbPyHwN7swaQ7FyCusfXGSjbcN7FRXC0HmSVVo3PSPUJ0jLES'
          }
          // {
          //   title: 'Filtri Colori Prova',
          //   path: '/colorFilters'
          // }
        ]
      },
      {
        title: 'Gift Cards',
        path: '/giftcards',
        icon: <CreditCardIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Analisi',
    items: [
      {
        title: 'Report Vendite',
        path: '/reports',
        icon: <ChartPieIcon fontSize="small" />,
        children: [
          {
            title: 'Nel Tempo',
            path: '/reports/sales/over-time'
          },
          {
            title: 'Per Marca',
            path: '/reports/sales/by-brand'
          },
          {
            title: 'Per Prodotto',
            path: '/reports/sales/by-product'
          },
          {
            title: 'Per Categoria',
            path: '/reports/sales/by-category'
          },
          {
            title: 'Valore Medio Ordini',
            path: '/reports/sales/average-value'
          },
          {
            title: 'Per Canale',
            path: '/reports/sales/by-store'
          },
          {
            title: 'Pagamenti',
            path: '/reports/finances/payments'
          },
          {
            title: 'Per Ordine',
            path: '/reports/sales-by-order'
          }
        ]
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <RouterLink to="/">
              <Logotfs
                sx={{
                  height: 40,
                  mt: 2
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/account">
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {`${user.first_name} ${user.last_name}`}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                Il tuo piano:
                {' '}
                <Link
                  color="primary"
                  component={RouterLink}
                  to="/pricing"
                >
                  {user.role.name}
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
        {/* <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Need Help?
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Check our docs
          </Typography>
          <Button
            color="primary"
            component={RouterLink}
            fullWidth
            sx={{ mt: 2 }}
            to="/docs"
            variant="contained"
          >
            Documentation
          </Button>
        </Box> */}
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
