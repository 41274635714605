import { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
// import MainLayout from './components/MainLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages
const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const CustomerCreate = Loadable(lazy(() => import('./pages/dashboard/CustomerCreate')));
const CustomerDetails = Loadable(lazy(() => import('./pages/dashboard/CustomerDetails')));
const CustomerEdit = Loadable(lazy(() => import('./pages/dashboard/CustomerEdit')));
const CustomerList = Loadable(lazy(() => import('./pages/dashboard/CustomerList')));
const OrderDetails = Loadable(lazy(() => import('./pages/dashboard/OrderDetails')));
const OrderList = Loadable(lazy(() => import('./pages/dashboard/OrderList')));
const OrderItemsList = Loadable(lazy(() => import('./pages/dashboard/OrderItemsList')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));
const BrandCreate = Loadable(lazy(() => import('./pages/dashboard/BrandCreate')));
const BrandEdit = Loadable(lazy(() => import('./pages/dashboard/BrandEdit')));
const BrandList = Loadable(lazy(() => import('./pages/dashboard/BrandList')));
const CategoryCreate = Loadable(lazy(() => import('./pages/dashboard/CategoryCreate')));
const CategoryEdit = Loadable(lazy(() => import('./pages/dashboard/CategoryEdit')));
const CategoryList = Loadable(lazy(() => import('./pages/dashboard/CategoryList')));
const ColorCreate = Loadable(lazy(() => import('./pages/dashboard/ColorCreate')));
const ColorEdit = Loadable(lazy(() => import('./pages/dashboard/ColorEdit')));
const ColorList = Loadable(lazy(() => import('./pages/dashboard/ColorList')));
// const ColorFilters = Loadable(lazy(() => import('./pages/dashboard/ColorFilters')));
const CouponList = Loadable(lazy(() => import('./pages/dashboard/CouponList')));
const CouponCreate = Loadable(lazy(() => import('./pages/dashboard/CouponCreate')));
const CouponEdit = Loadable(lazy(() => import('./pages/dashboard/CouponEdit')));
const DDTCreate = Loadable(lazy(() => import('./pages/dashboard/DDTCreate')));
const DDTList = Loadable(lazy(() => import('./pages/dashboard/DDTList')));
const Inventory = Loadable(lazy(() => import('./pages/dashboard/Inventory')));
const InventoryCreate = Loadable(lazy(() => import('./pages/dashboard/InventoryCreate')));
const MovementList = Loadable(lazy(() => import('./pages/dashboard/MovementList')));
const MovementCreate = Loadable(lazy(() => import('./pages/dashboard/MovementCreate')));
const MovementEdit = Loadable(lazy(() => import('./pages/dashboard/MovementEdit')));
const ProductCreate = Loadable(lazy(() => import('./pages/dashboard/ProductCreate')));
const ProductEdit = Loadable(lazy(() => import('./pages/dashboard/ProductEdit')));
const ProductList = Loadable(lazy(() => import('./pages/dashboard/ProductList')));
const RestockingList = Loadable(lazy(() => import('./pages/dashboard/RestockingList')));
const SaleList = Loadable(lazy(() => import('./pages/dashboard/SaleList')));
const SeasonCreate = Loadable(lazy(() => import('./pages/dashboard/SeasonCreate')));
const SeasonEdit = Loadable(lazy(() => import('./pages/dashboard/SeasonEdit')));
const SeasonList = Loadable(lazy(() => import('./pages/dashboard/SeasonList')));
const LabelCreate = Loadable(lazy(() => import('./pages/dashboard/LabelCreate')));
const LabelEdit = Loadable(lazy(() => import('./pages/dashboard/LabelEdit')));
const LabelList = Loadable(lazy(() => import('./pages/dashboard/LabelList')));
const ShippingExpensesList = Loadable(lazy(() => import('./pages/dashboard/ShippingExpensesList')));
const ShippingExpensesCreate = Loadable(lazy(() => import('./pages/dashboard/ShippingExpensesCreate')));
const ShippingExpensesEdit = Loadable(lazy(() => import('./pages/dashboard/ShippingExpensesEdit')));
const ShippingMethodsCreate = Loadable(lazy(() => import('./pages/dashboard/ShippingMethodsCreate')));
const ShippingMethodsEdit = Loadable(lazy(() => import('./pages/dashboard/ShippingMethodsEdit')));
const ShippingMethodsList = Loadable(lazy(() => import('./pages/dashboard/ShippingMethodsList')));
const ShippingZonesList = Loadable(lazy(() => import('./pages/dashboard/ShippingZonesList')));
const ShippingZonesCreate = Loadable(lazy(() => import('./pages/dashboard/ShippingZonesCreate')));
const ShippingZonesEdit = Loadable(lazy(() => import('./pages/dashboard/ShippingZonesEdit')));
const SizeCreate = Loadable(lazy(() => import('./pages/dashboard/SizeCreate')));
const SizeEdit = Loadable(lazy(() => import('./pages/dashboard/SizeEdit')));
const SizeList = Loadable(lazy(() => import('./pages/dashboard/SizeList')));
const SizeCategoriesCreate = Loadable(lazy(() => import('./pages/dashboard/SizeCategoriesCreate')));
const SizeCategoriesEdit = Loadable(lazy(() => import('./pages/dashboard/SizeCategoriesEdit')));
const SizeCategoriesList = Loadable(lazy(() => import('./pages/dashboard/SizeCategoriesList')));
const SizeChartCreate = Loadable(lazy(() => import('./pages/dashboard/SizeChartCreate')));
const SizeChartEdit = Loadable(lazy(() => import('./pages/dashboard/SizeChartEdit')));
const SizeChartList = Loadable(lazy(() => import('./pages/dashboard/SizeChartList')));
const TypeCreate = Loadable(lazy(() => import('./pages/dashboard/TypeCreate')));
const TypeEdit = Loadable(lazy(() => import('./pages/dashboard/TypeEdit')));
const TypeList = Loadable(lazy(() => import('./pages/dashboard/TypeList')));
const WarehouseList = Loadable(lazy(() => import('./pages/dashboard/WarehouseList')));

// Content
const ContentCreate = Loadable(lazy(() => import('./pages/dashboard/ContentHome')));
const ContentPage = Loadable(lazy(() => import('./pages/dashboard/ContentPage')));
// const BrandEdit = Loadable(lazy(() => import('./pages/dashboard/BrandEdit')));
// const BrandList = Loadable(lazy(() => import('./pages/dashboard/BrandList')));
const GiftCardList = Loadable(lazy(() => import('./pages/dashboard/GiftCardList')));

// Reports
const Reports = Loadable(lazy(() => import('./pages/dashboard/Reports')));
const FinancesPayments = Loadable(lazy(() => import('./pages/dashboard/ReportFinancesPayments')));
const ReportOrders = Loadable(lazy(() => import('./pages/dashboard/ReportOrders')));
const SalesAverageValue = Loadable(lazy(() => import('./pages/dashboard/ReportSalesAverageValue')));
const SalesByBrand = Loadable(lazy(() => import('./pages/dashboard/ReportSalesByBrand')));
const SalesByCategory = Loadable(lazy(() => import('./pages/dashboard/ReportSalesByCategory')));
const SalesByProduct = Loadable(lazy(() => import('./pages/dashboard/ReportSalesByProduct')));
const SalesByStore = Loadable(lazy(() => import('./pages/dashboard/ReportSalesByStore')));
const SalesOverTime = Loadable(lazy(() => import('./pages/dashboard/ReportSalesOverTime')));
const SalesByOrder = Loadable(lazy(() => import('./pages/dashboard/ReportSalesByOrder')));

// Pages
const PagesContacts = Loadable(lazy(() => import('./pages/dashboard/PagesContacts')));
const PagesTermsAndConditions = Loadable(lazy(() => import('./pages/dashboard/PagesTermsAndConditions')));
const PagesPrivacyPolicy = Loadable(lazy(() => import('./pages/dashboard/PagesPrivacyPolicy')));
const PagesCookies = Loadable(lazy(() => import('./pages/dashboard/PagesCookies')));
const PagesReturns = Loadable(lazy(() => import('./pages/dashboard/PagesReturns')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <Overview />
      },
      {
        path: 'account',
        element: <Account />
      },
      {
        path: 'customers',
        children: [
          {
            path: '/',
            element: <CustomerList />
          },
          {
            path: '/:id',
            element: <CustomerDetails />
          },
          {
            path: 'new',
            element: <CustomerCreate />
          },
          {
            path: '/:id/edit',
            element: <CustomerEdit />
          }
        ]
      },
      {
        path: 'coupons',
        children: [
          {
            path: '/',
            element: <CouponList />
          },
          {
            path: 'new',
            element: <CouponCreate />
          },
          {
            path: '/:id/edit',
            element: <CouponEdit />
          }
        ]
      },
      {
        path: 'orders',
        children: [
          {
            path: '',
            element: <OrderList />
          },
          {
            path: '/sales',
            element: <SaleList />
          },
          {
            path: ':id',
            element: <OrderDetails />
          },
          {
            path: '/items',
            element: <OrderItemsList />
          }
        ]
      },
      {
        path: 'products',
        children: [
          {
            path: '/',
            element: <ProductList />
          },
          {
            path: 'new',
            element: <ProductCreate />
          },
          {
            path: '/:id/edit',
            element: <ProductEdit />
          },
          {
            path: 'variations',
            element: <Inventory />
          },
          {
            path: 'variations/new',
            element: <InventoryCreate />
          },
          {
            path: 'brands',
            element: <BrandList />
          },
          {
            path: 'brands/new',
            element: <BrandCreate />
          },
          {
            path: 'brands/:id/edit',
            element: <BrandEdit />
          },
          {
            path: 'categories',
            element: <CategoryList />
          },
          {
            path: 'categories/new',
            element: <CategoryCreate />
          },
          {
            path: 'categories/:id/edit',
            element: <CategoryEdit />
          },
          {
            path: 'colors',
            element: <ColorList />
          },
          {
            path: 'colors/new',
            element: <ColorCreate />
          },
          {
            path: 'colors/:id/edit',
            element: <ColorEdit />
          },
          {
            path: 'seasons',
            element: <SeasonList />
          },
          {
            path: 'seasons/new',
            element: <SeasonCreate />
          },
          {
            path: 'seasons/:id/edit',
            element: <SeasonEdit />
          },
          {
            path: 'labels',
            element: <LabelList />
          },
          {
            path: 'labels/new',
            element: <LabelCreate />
          },
          {
            path: 'labels/:id/edit',
            element: <LabelEdit />
          },
          {
            path: 'size_categories',
            element: <SizeCategoriesList />
          },
          {
            path: 'size_categories/new',
            element: <SizeCategoriesCreate />
          },
          {
            path: 'size_categories/:id/edit',
            element: <SizeCategoriesEdit />
          },
          {
            path: 'sizes',
            element: <SizeList />
          },
          {
            path: 'sizes/new',
            element: <SizeCreate />
          },
          {
            path: 'sizes/:id/edit',
            element: <SizeEdit />
          },
          {
            path: 'types',
            element: <TypeList />
          },
          {
            path: 'types/new',
            element: <TypeCreate />
          },
          {
            path: 'types/:id/edit',
            element: <TypeEdit />
          },
          {
            path: 'size_charts',
            element: <SizeChartList />
          },
          {
            path: 'size_charts/new',
            element: <SizeChartCreate />
          },
          {
            path: 'size_charts/:id/edit',
            element: <SizeChartEdit />
          }
        ]
      },
      {
        path: 'contents',
        children: [
          {
            path: 'home',
            element: <ContentCreate />
          },
          {
            path: 'page/:id',
            element: <ContentPage />
          }
        ]
      },
      {
        path: 'warehouses',
        element: <WarehouseList />
      },
      // {
      //   path: 'colorFilters',
      //   element: <ColorFilters />
      // },
      {
        path: 'movements',
        element: <MovementList />
      },
      {
        path: 'movements/new',
        element: <MovementCreate />
      },
      {
        path: 'movements/:id/edit',
        element: <MovementEdit />
      },
      {
        path: 'ddt',
        element: <DDTList />
      },
      {
        path: 'ddt/new',
        element: <DDTCreate />
      },
      {
        path: 'restocking',
        element: <RestockingList />
      },
      {
        path: 'giftcards',
        element: <GiftCardList />
      },
      {
        path: 'stores',
        children: [
          {
            path: '/3/zones',
            element: <ShippingZonesList />
          },
          {
            path: '/3/zones/new',
            element: <ShippingZonesCreate />
          },
          {
            path: '/3/zones/:id/edit',
            element: <ShippingZonesEdit />
          },
          {
            path: '/3/methods',
            element: <ShippingMethodsList />
          },
          {
            path: '/3/methods/new',
            element: <ShippingMethodsCreate />
          },
          {
            path: '/3/methods/:id/edit',
            element: <ShippingMethodsEdit />
          },
          {
            path: '/3/shippings',
            element: <ShippingExpensesList />
          },
          {
            path: '/3/shippings/new',
            element: <ShippingExpensesCreate />
          },
          {
            path: '/3/shippings/:id/edit',
            element: <ShippingExpensesEdit />
          }
        ]
      },
      {
        path: 'reports',
        children: [
          {
            path: '/',
            element: <Reports />
          },
          {
            path: 'sales/over-time',
            element: <SalesOverTime />
          },
          {
            path: 'orders/over-time',
            element: <ReportOrders />
          },
          {
            path: 'sales/by-product',
            element: <SalesByProduct />
          },
          {
            path: 'sales/by-brand',
            element: <SalesByBrand />
          },
          {
            path: 'sales/by-store',
            element: <SalesByStore />
          },
          {
            path: 'sales/by-category',
            element: <SalesByCategory />
          },
          {
            path: 'sales/average-value',
            element: <SalesAverageValue />
          },
          {
            path: 'finances/payments',
            element: <FinancesPayments />
          },
          {
            path: 'sales-by-order',
            element: <SalesByOrder />
          }
        ]
      },
      {
        path: 'pages',
        children: [
          {
            path: '/contacts',
            element: <PagesContacts />
          },
          {
            path: '/returns-and-refunds',
            element: <PagesReturns />
          },
          {
            path: '/terms-and-conditions',
            element: <PagesTermsAndConditions />
          },
          {
            path: '/privacy-policy',
            element: <PagesPrivacyPolicy />
          },
          {
            path: '/cookies',
            element: <PagesCookies />
          }
        ]
      }
    ]
  },
  {
    path: '*',
    // element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
        children: [
          {
            path: '/',
            element: <Overview />
          }
        ]
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
