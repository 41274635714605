import { useRef, useState, useEffect } from 'react';
import {
  Avatar,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api } from '../../config';
import BellIcon from '../../icons/Bell';
import ChatAltIcon from '../../icons/ChatAlt';
import CreditCardIcon from '../../icons/CreditCard';
import ShoppingCartIcon from '../../icons/ShoppingCart';

const iconsMap = {
  item_shipped: ShoppingCartIcon,
  new_message: ChatAltIcon,
  order_placed: CreditCardIcon
};

const NotificationsPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getNotifications = async () => {
    const response = await axios.get(`${api.url}/items/orders`, { params: {
      filter: {
        _and: [
          {
            read: {
              _eq: false
            }
          },
          {
            statuses_id: {
              _in: [2, 3]
            }
          },
          {
            stores_id: {
              _eq: 3
            }
          }
        ]
      },
      sort: ['-created_at']
    } });
    setNotifications(response.data.data.map((order) => ({
      id: order.id,
      createdAt: new Date(order.created_at),
      description: `${order.name} ${order.surname} ha effettuato un nuovo ordine da ${order.city}`,
      title: `Ordine ${order.id}`,
      type: 'order_placed'
    })));
  };

  useEffect(() => {
    getNotifications();
    const interval = setInterval(getNotifications, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Tooltip title="Notifiche">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={notifications.length}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 320 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            Notifiche
          </Typography>
        </Box>
        {notifications.length === 0
          ? (
            <Box sx={{ p: 2 }}>
              <Typography
                color="textSecondary"
                variant="subtitle2"
              >
                Nessun nuovo aggiornamento
              </Typography>
            </Box>
          )
          : (
            <>
              <List disablePadding>
                {notifications.map((notification) => {
                  const Icon = iconsMap[notification.type];

                  return (
                    <ListItem
                      divider
                      key={notification.id}
                    >
                      <ListItemAvatar>
                        <Avatar
                          sx={{
                            backgroundColor: 'primary.main',
                            color: 'primary.contrastText'
                          }}
                        >
                          <Icon fontSize="small" />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={(
                          <Link
                            color="textPrimary"
                            sx={{ cursor: 'pointer' }}
                            underline="none"
                            variant="subtitle2"
                            onClick={async () => {
                              navigate(`/orders/${notification.id}`);
                              await axios.patch(`${api.url}/items/orders/${notification.id}`, { read: true });
                              getNotifications();
                            }}
                          >
                            {notification.title}
                          </Link>
                        )}
                        secondary={notification.description}
                      />
                    </ListItem>
                  );
                })}
              </List>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1
                }}
              >
                <Button
                  color="primary"
                  size="small"
                  variant="text"
                  onClick={async () => {
                    await axios.patch(`${api.url}/items/orders`, { keys: notifications.map((order) => order.id), data: { read: true } });
                    getNotifications();
                  }}
                >
                  Segna tutte come lette
                </Button>
              </Box>
            </>
          )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
